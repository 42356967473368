@font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-Regular'), url('@assets/fonts/regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-Medium'), url('@assets/fonts/medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-Bold'), url('@assets/fonts/bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-Heavy'), url('@assets/fonts/heavy.ttf');
  font-weight: 900;
}

/* Unused font */
/* @font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-RegularItalic'), url('@assets/fonts/regular-italic.ttf');
  font-weight: 500;
  font-style: italic;
} */

@font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-Light'), url('@assets/fonts/light.ttf');
  font-weight: 300;
}

/* Unused font */
/* @font-face {
  font-family: 'Default-Font';
  src: local('Default-Font-LightItalic'), url('@assets/fonts/light.ttf');
  font-weight: 300;
  font-style: italic;
} */

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

body {
  margin: 0;
  font-family: 'Default-Font', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition-duration: 0s !important;
  }
}

.no-transition * {
  transition: none !important;
  animation: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}
